var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "add-tg"
  }, [_c('div', {
    staticClass: "logo-image"
  }, [_c('a', {
    staticClass: "sidebar__logo",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/logo.svg`),
      "alt": "Logo"
    }
  })]), !_vm.collapsed ? _c('span', {
    staticClass: "logo-sign"
  }, [_vm._v("®")]) : _vm._e()]), _c('div', {
    staticClass: "add-tg-text"
  }, [_vm.error && !_vm.logged ? _c('div', {
    staticClass: "add-tg-text__block"
  }, [_c('span', [_vm._v(" Если у Вас уже есть аккаунт то ")]), _c('router-link', {
    staticClass: "add-tg-button",
    attrs: {
      "to": {
        name: 'Login',
        query: {
          backUrl: `/addtgbot/${this.tgBotId}`
        }
      }
    }
  }, [_vm._v(" Авторизуйтесь ")]), _c('span', [_vm._v("или оздайте новый аккаунт ")]), _c('router-link', {
    staticClass: "add-tg-button",
    attrs: {
      "to": {
        name: 'Register',
        query: {
          backUrl: `/addtgbot/${this.tgBotId}`
        }
      }
    }
  }, [_vm._v(" Зарегистрироваться ")])], 1) : _vm.error && _vm.logged ? _c('span', [_vm._v(" Данная ссылка устарела, запросите в боте еще раз. ")]) : _vm.success ? _c('span', [_vm._v(" Успешно подключен аккаунт Telegram! "), _c('br'), _vm._v(" Изменить или отключить Вы можете в личном кабинете. ")]) : _vm._e()]), _c('a', {
    staticClass: "add-tg-button",
    attrs: {
      "href": "https://t.me/SalesFinderAnalytics_Bot",
      "target": "_blank"
    }
  }, [_vm._v(" Вернуться в Telegram ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };